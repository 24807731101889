@import '../../variables';

.UpgradePlan {
  text-align: center;

  .container {
    display: flex;
    justify-content: space-around;
    width: 550px;
    margin: 0 auto;
  }

  .UpgradePlan__skip {
    margin-top: 50px;
    color: $grey-light;
    font-size: 13px;
    text-decoration: underline;

    & span {
      cursor: pointer;
    }
  }
}
