@import '../../variables';

.StateRadioGroup {
  display: grid;
  grid-template-columns: repeat(3, 105px);
  align-items: center;
  place-content: center;
  column-gap: 37px;

  .StateRadio__box {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-medium;
    text-align: center;
    background-color: $white;
    box-shadow: $default-box-shadow;
    cursor: pointer;
    user-select: none;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
    }

    &::after {
      top: -3px;
      left: -3px;
      z-index: -2;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      border-radius: $border-radius-medium;
    }

    &::before {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background: $white;
    }

    &--done {
      &::after {
        background: linear-gradient(180deg, #4DCCAE, #2DDD9D);
      }
    }

    &--half-done {
      &::after {
        background: linear-gradient(0deg, #FFE37B, #FFD166);
      }
    }

    &--not-done {
      &::after {
        background: linear-gradient(0deg, #F3908C, #FF6763);
      }
    }
  }

  .StateRadio__text {
    display: block;
    margin-top: 6px;
    color: $grey-light;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }

  input {
    display: none;
  }
}
