@import '../../../variables';

.TodayGoal {
  color: $white;

  .TodayGoal__goal {
    display: flex;
    margin-top: 10px;
    font-size: 15px;
    line-height: 18px;
  }

  .TodayGoal__edit {
    width: 100%;
    min-height: 36px;
  }

  .TodayGoal__edit input {
    width: 100%;
    border: none;
    color: $white;
    font-size: 15px;
    line-height: 18px;
    background: transparent;
    outline: none;
  }

  .TodayGoal__text {
    display: -webkit-box;
    /* autoprefixer: off */
    width: 100%;
    height: 36px;
    overflow: hidden;
    cursor: pointer;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .TodayGoal__icon {
    width: 60px;
  }

  .TodayGoal__icon--goal {
    margin-right: 10px;
    transform: translateY(-1px);
  }

  .TodayGoal__icon--edit {
    align-self: flex-end;
    transform: translateX(-3px);
  }

  .TodayGoal__icon--edit:hover {
    cursor: pointer;
  }
}
