@import "../../../../variables";

.SettingsWebhook {
  .SettingsContent__wrapper {
    margin-top: 48px;
  }

  .SettingsWebhook__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    padding-top: 70px;
    background: rgba($white, 0.9);
  }

  .overlay__title {
    margin-bottom: 30px;
    color: $grey-dark;
    font-weight: bold;
    font-size: 32px;
    text-align: center;
  }

  .SettingsWebhook__input-wrapper {
    margin-left: 67px;
  }

  .SettingsWebhook__intro-question-wrapper {
    position: relative;
    display: inline-block;
  }

  .SettingsWebhook__intro-question {
    position: relative;
    top: 3px;
    display: inline-block;
  }

  .Input__wrapper,
  .toggle {
    display: block;
  }

  .Input__wrapper {
    margin-bottom: 1em;
  }

  .toggle {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .SettingsWebhook__label {
    display: block;
    padding-bottom: 1em;
    text-align: left;
  }

  .SettingsWebhook__helper-text {
    color: $grey-light;
    font-size: 14px;
  }

  .Input__wrapper + .SettingsWebhook__helper-text {
    margin-top: 1.5em;
  }

  code {
    padding: 0.5em;
    border: 1px solid $mercury;
    border-radius: $border-radius-medium;
    color: $dusty-grey;
    font-family: courier, monospace;
    background: $alabaster;
  }

  .SettingsWebhook__examples {
    position: relative;
    margin-top: 6em;

    strong {
      text-decoration: underline;
    }

    &::before {
      position: absolute;
      top: -1.5em;
      display: block;
      width: 100%;
      height: 2px;
      content: "";
      background: linear-gradient(90deg, transparent, $alabaster 5%, $alabaster 95%, transparent);
    }
  }

  .SettingsWebhook__input-error {
    margin-bottom: 2em;
    color: $error-red;
  }

  .btn--gradient {
    position: relative;
    margin-right: 15px;
    padding-left: 35px;
    color: $tundora;
    font-weight: bold;
    font-size: 14px;

    svg {
      position: absolute;
      top: 8px;
      left: 7px;
      width: 23px;
      height: 23px;
    }
  }

  input {
    width: 100%;
  }

  label {
    align-self: center;
    padding-right: 1em;
    color: $grey-dark;
    text-align: right;
  }

  .Tooltip {
    top: 30px;
    right: auto;
    bottom: auto;
    left: -10px;
    z-index: 3;
    width: 270px;
    text-align: left;

    &::after {
      right: auto;
      left: 1em;
    }

    &--visible {
      opacity: 1;
    }

    p + p {
      margin-top: 1em;
    }

    a {
      color: $burnt-sienna;
    }
  }
}
