@import '../../variables';

.OnboardingSuccess {
  height: 100vh;
  color: $white;
  background: url('../../images/onboarding-bg.png') no-repeat bottom / contain;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .OnboardingSuccess__title {
	margin-top: 150px;
    font-size: 50px;
  }

  .OnboardingSuccess__caption {
	max-width: 500px;
    margin: 20px 0 60px;
    font-size: 20px;
	line-height: 24px;
	text-align: center;
  }

  .OnboardingSuccess__btn {
    width: 43%;
	padding-top: 15px;
	padding-bottom: 15px;
  }
}
