@import '../../variables';

.ConfirmationModal {
  position: relative;
  width: 380px;
  padding: 40px 30px;

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
  }

  .ConfirmationModal__buttons {
    margin-top: 20px;

    .Button {
      width: 135px;
      height: 44px;

      .btn__gradient-text {
        padding-top: 14px;
      }

      &:first-child {
        margin-right: 20px;
      }
    }

    .btn--primary .btn__gradient-text {
      padding-top: 10px;
    }
  }

  &--loading {
    opacity: 0.7;
    pointer-events: none;
  }

  &--loading:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 380px;
    height: 200px;
    border-radius: 4px;
    background: $porcelain;
    background: linear-gradient(
      to right,
      $mercury 8%,
      $gainsboro 18%,
      $mercury 33%
    );
    background-size: 350px 50px;
    opacity: 0.5;
    animation: loading 1.7s infinite forwards linear;
    content: '';
  }
}
