@import '../../variables';

.SetDayGoal {
  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: 75px;
  color: $white;
  text-align: center;

  .container {
    margin: 0 auto 150px;
  }

  .SetDayGoal__header {
    font-size: 44px;
  }

  .SetDayGoal__subtitle {
    margin: 30px 0;
    font-weight: normal;
    font-size: 20px;
  }

  .SetDayGoal__submit-btn {
    margin-top: 30px;
    font-size: 24px;

    &[disabled] {
      opacity: 0.6;
    }
  }

  .SetDayGoal__submit-btn,
  .ResizableTextArea {
    width: 65%;
  }
}
