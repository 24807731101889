@import '../../variables';

.Team_onboarding {
  max-width: 676px;
  margin: 124px auto 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &:before {
    content: '';
    background-image: url('../../images/team-onboarding-bg.png');
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: -30px;
    left: 0;
  }

  form {
    .Input__wrapper {
      width: 100%;

      .Input[type='text'] {
        width: 80%;
        background-color: rgba($black, 0.18);
        margin-top: 0.5em;
        font-weight: 400;
        font-size: 25px;
        color: $white;

        &::placeholder {
          color: rgba($white, 0.6);
        }

        &.invalid {
          border-color: $error-red;
          color: $error-red;
        }
      }
    }

    a {
      color: inherit;
    }

    label {
      cursor: pointer;
      width: 540px;
      display: inline-block;
      color: $white;
      font-weight: bold;
      text-align: left;
    }
    .onboarding__button {
      margin-top: 0.3em;
      text-align: center;
    }
  }

  .onboarding__title {
    font-size: 50px;
    line-height: 60px;
  }
  .onboarding__title,
  .onboarding__sub-title {
    margin-top: 0.25em;
    margin-bottom: 0;
    color: $white;
    font-weight: bold;
    text-align: center;
  }

  .onboarding__sub-title {
    margin-top: 0.25em;
    font-weight: normal;
    font-size: 20px;
    margin: 16px 0 0 0;
  }

  .onboarding__row {
    text-align: center;

    &:first-child {
      margin: 39px 0 0 0;
    }

    &:nth-child(2) {
      margin: 32px 0 0 0;
    }
  }

  .onboarding__row-link {
    text-decoration: underline;
    cursor: pointer;
  }
}
