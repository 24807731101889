@import '../../variables';

.CurrentSession {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
  }

  .CurrentSession__header {
    padding-top: 40px;
  }

  .CurrentSession__main {
    display: inline-block;
    padding-top: 60px;
    color: $white;
  }

  .main__session-number {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-decoration: underline;
  }

  .main__title {
    display: -webkit-box;
    overflow: hidden;
    font-weight: bold;
    font-size: 40px;
    /* autoprefixer: off */
    text-align: center;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .main__session-starting {
    display: -webkit-box;
    /* autoprefixer: off */
    margin-top: 10px;
    overflow: hidden;
    font-size: 20px;
    font-style: italic;
    text-align: center;
    text-shadow: 0 0 4px 0 rgba($black, 0.5);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .main__counter {
    width: 620px;
    margin: -20px 0;
    text-align: center;
  }

  .main__counter--session {
    margin: -50px auto;
  }

  .main__counter-remaining {
    font-weight: 300;
    font-size: 25px;
    transform: translateY(-160px);
  }

  .CurrentSession__footer {
    position: relative;
    width: 400px;
    margin-top: -40px;
    color: $grey-dark;
    text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  }

  .footer__info {
    width: 240px;
    margin: 0 auto;
    color: $white;
    font-size: 14px;
    font-style: italic;
    text-align: center;
  }

  .footer__buttons {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
    padding: 0 80px;

    &--higher {
      margin-top: 40px;
    }
  }

  .buttons__btn {
    width: 35px;
    height: 35px;
    cursor: pointer;

    &--session-paused {
      cursor: default;
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .footer__shortcut {
    position: absolute;
    bottom: -25px;
    left: 38px;
    width: 140px;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -1px;
    opacity: 0.3;

    p {
      text-align: center;
    }
  }
}
