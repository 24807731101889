@import "../../../../variables";

.SessionsSettings {
  .SettingsContent__wrapper {
    margin-top: 48px;
  }

  .SessionsSettings__row {
    display: grid;
    grid-template-columns: max-content auto;
  }

  .select-wrapper {
    position: relative;
    width: auto;
  }

  label {
    padding-right: 1em;
    color: $grey-dark;
    text-align: right;
    cursor: pointer;
  }
}
