@import '../../../variables';

.Dashboard {
  .Dashboard__blocks {
    display: grid;
    grid-gap: 12px;
    margin-top: 1.7em;
    color: $white;

    &--halfs {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .Dashboard__block {
    padding: 1.1em 1.25em 1.6em;
    border-radius: $border-radius-medium;
    font-weight: bold;
    text-align: center;
    background-color: rgba($white, 0.15);
    box-shadow: $default-box-shadow;
  }

  .Dashboard__block--graph {
    width: 443px;
    height: 145px;
    padding: 0.7em 0 1em;
  }

  .Dashboard__block-value {
    font-size: 38px;
  }

  .Dashboard__block-title {
    font-size: 12px;
    text-transform: uppercase;
  }

  .Dashboard__block-distractions {
    width: 80%;
    margin-top: 3em;
    color: $white;

    h2 {
      padding-bottom: 0.5em;
      line-height: 1.21;
    }
  }

  .Dashboard__great-work {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;

    .btn--gradient::before {
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
    }

    .great-work__heading {
      margin: 140px 0 50px;
    }

    .heading__subtitle {
      color: $grey-light;
      font-weight: normal;
      font-size: 16px;
    }

    .greak-work__plan-tomorrow {
      margin-top: 4em;
      padding: 1.5em;
      border-top: 1px solid $concrete;

      button {
        border: none;
        color: $grey-light;
        font-size: 15px;
        text-decoration: underline;
        background: none;
        outline: none;
        cursor: pointer;
      }
    }
  }

  .Dashboard__goal {
    margin-top: 204px;
    font-weight: bold;
    text-align: center;

    .GoSereneButton {
      width: 60%;
      min-width: 340px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .Dashboard__goal-intro,
  .Dashboard__goal-text {
    color: $grey-light;
  }

  .Dashboard__goal-title {
    font-size: 45px;
  }

  .Dashboard__goal-title--no-goal {
    padding-top: 53px;
  }

  .Dashboard__goal-intro {
    font-size: 12px;
    text-transform: uppercase;
  }

  .Dashboard__goal-text {
    display: -webkit-box;
    max-width: 480px;
    margin: 0 auto;
    padding: 0 20px;
    overflow: hidden;
    font-size: 24px;
    -webkit-line-clamp: 3;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .Dashboard__footer {
    position: absolute;
    right: 0;
    bottom: 1em;
    width: 100%;
    padding: 2em 2em 0;
    text-align: center;

    &::before {
      position: absolute;
      top: 0;
      left: 2em;
      display: block;
      width: calc(100% - 4em);
      height: 1px;
      background: linear-gradient(
        to right,
        $white,
        $mercury 5%,
        $mercury 95%,
        $white 100%
      );
      content: '';
    }

    h2 {
      font-size: 18px;
      line-height: 1.167;
    }
  }

  .Dashboard__help-arrow {
    position: relative;

    > svg {
      position: absolute;
      top: 3em;
      left: -1em;
      z-index: 1;
      transform: scaleY(-1) rotate(160deg);
    }
  }
}
