@import '../../../variables';

@mixin inputEffects {
  $transition: 300ms ease-in;

  transition:
    box-shadow $transition,
    border $transition,
    background-color $transition;

  &:hover {
    box-shadow: 0 0 11px -2px rgba($body-color, 0.25);
  }

  &:focus {
    background-color: $mercury;
    box-shadow: 0 0 11px -2px rgba($body-color, 0.25);
  }

  &[disabled] {
    color: $grey-dark;
    box-shadow: none;
  }
}

.Input__wrapper {
  position: relative;
  display: inline-block;

  &.Input__wrapper--wide {
    width: 100%;
  }
}

.Input {
  position: relative;
  display: inline-block;
  width: 230px;
  padding: 9px 20px 10px;
  border: 2px solid transparent;
  border-radius: $border-radius-medium;
  color: $grey-dark;
  font-weight: 300;
  font-size: 26px;
  line-height: 31px;
  background-color: $select-bg;
  outline: none;

  @include inputEffects();

  &::placeholder {
    color: rgba($body-color, 0.26);
  }

  &.Input--invalid {
    border: 2px solid $error-red;
    color: $error-red;
  }
}

.InputSelect {
  position: relative;
  display: inline-block;
  border-radius: $border-radius-medium;
  background-color: $select-bg;

  .InputSelect__select {
    @extend .Input;
    background: transparent;

    cursor: pointer;
    appearance: none;
  }

  .InputSelect__icon {
    position: absolute;
    top: calc(50% - 2px);
    right: 20px;
    z-index: 1;
    pointer-events: none;
  }

  &.InputSelect--small {
    bottom: 8px;
    margin-bottom: 16px;

    .InputSelect__select {
      width: auto;
      padding: 8px 25px 8px 12px;
      font-size: 16px;
      line-height: 16px;
    }

    .InputSelect__icon {
      right: 8px;
    }
  }
}
