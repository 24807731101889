@import '../../../../variables';

.Menu {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 25px 0 20px;

  h1 {
    display: block;
    margin: 33px 0 0;
    color: $white;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
  }

  h3 {
    display: block;
    margin: 18px 0;
    color: $white;
    font-weight: bold;
    font-size: 12px;
  }
}

.Menu--without-navigation {
  padding-top: 20px;
}
