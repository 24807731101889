.Layout {
  display: grid;
  grid-template-columns: 288px auto;
}

.Layout--wide {
  grid-template-columns: 410px auto;
}

.Layout--super-wide {
  grid-template-columns: 488px auto;
}
