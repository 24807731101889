@import '../../../variables';

.GoSereneButton {
  position: relative;
}

.GoSereneButton__bg {
  text-align: center;

  svg {
    opacity: 0.45;
  }
}

.GoSereneButton__content {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  width: 100%;
  transform: translate(-50%, -50%);
}

.GoSereneButton__icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.GoSereneButton--disabled {
  cursor: initial;
  opacity: 0.4;
  pointer-events: none;
}
