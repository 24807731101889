@import '../../../variables';

.NotificationCard {
  position: absolute;
  top: 1em;
  right: 1em;
  width: 283px;
  padding: 14px 15px 15px;
  border-radius: 4px;
  color: $grey-dark;
  font-size: 14px;
  line-height: 1.2;
  background-color: $white;
  box-shadow: $default-box-shadow;
  transition: transform 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55);

  a {
    color: inherit;
  }

  .NotificationCard__icon {
    float: left;
    margin-right: 10px;
  }

  .NotificationCard__heading {
    margin-bottom: 10px;
    font-weight: 700;
  }

  .heading__number {
    color: $coral-serene;
  }

  .NotificationCard__content {
    font-size: 12px;
  }

  .NotificationCard__copy-link {
    text-align: right;

    span {
      font-size: 10px;
      text-decoration: underline;
      cursor: pointer;
    }

    span.clicked {
      text-decoration: none;
      cursor: initial;
    }
  }
}
