@import '../../variables';

.Blockapps {
  position: relative;
  width: 460px;
  height: 321px;
  border-radius: $border-radius-medium;
  overflow: hidden;
  overflow-y: auto;
  text-align: left;
  background-color: $white;
  box-shadow: $default-box-shadow;

  .Blockapps-list {
    height: calc(4 * 64px);
    overflow: auto;
  }

  .Blockapps-list__search {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 65px;
    padding: 15px 0 15px 40px;
    color: $burnt-sienna;
    box-shadow: 0 15px 40px 0 rgba($black, 0.75);
  }

  .search__btn {
    width: 35px;
    height: 35px;
    margin-right: 20px;
    border-radius: $border-radius-medium;
    font-size: 30px;
    background-color: $alabaster;
    cursor: pointer;
  }

  .search__btn span {
    display: block;
    margin-left: 9px;
    line-height: 30px;
  }

  .search__title {
    font-weight: 500;
    cursor: pointer;
  }

  .search__title--limited {
    width: 86%;
    color: $grey-dark;
    text-align: center;
    cursor: default;
    pointer-events: none;
  }

  .search__title--upgrade {
    width: 91%;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;

    & span {
      font-weight: 600;
    }
  }
}
