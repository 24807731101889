@import "../../../variables";

.select {
  position: relative;
  z-index: 2;
  padding-right: 1em;
  border: 0;
  border-radius: 0;
  background: transparent;
  outline: none;
  appearance: none;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  border-radius: 3px;
}

.select__icon {
  position: absolute;
  top: 50%;
  right: 0.5em;
  z-index: 1;
  pointer-events: none;
}
