@import '../../../variables';

.Sessions {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 60%;
    height: 100vh;
    margin: 0 auto;
    color: $white;
    text-align: center;
  }

  .Sessions__header {
    font-size: 44px;
  }

  .Sessions__subtitle {
    margin: 30px 0;
    font-weight: 100;
    font-style: italic;
  }

  .Sessions__textarea {
    border: none;
    border-radius: 5px;
    color: $white;
    font-size: 20px;
    text-align: left;
    background-color: rgba($white, 0.2);
    outline: none;
    resize: none;
  }

  .Sessions__textarea::placeholder {
    color: $white;
  }

  .Sessions__submit-btn {
    width: 100%;
    margin-top: 30px;
    font-size: 24px;
  }
}
