@import '../../variables';

.OnboardingBlockApps {
  height: 100vh;
  color: $white;
  background: url('../../images/onboarding-bg.png') no-repeat bottom / contain;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .OnboardingBlockApps__title {
    font-size: 36px;
  }

  .OnboardingBlockApps__caption {
    margin: 20px 0 30px;
    font-size: 20px;
  }

  .OnboardingBlockApps__toggle-wrapper {
    width: 50%;
    margin: 20px 0 20px 100px;
  }

  .OnboardingBlockApps__btn {
    width: 43%;
  }
}
