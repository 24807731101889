@import '../../../variables';

@mixin SessionItem__box {
  height: 87px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: $border-radius-medium;
}

.SessionItem {
  @include SessionItem__box;
  font-size: 12px;
  line-height: 14px;
  background-color: $white;
  box-shadow: $default-box-shadow;
}

.SessionItem__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $grey-light;
  font-weight: bold;

  .header__state {
    width: 19px;
    height: 19px;
    margin-right: 7px;
  }

  .header__session-number {
    flex: 2;
  }

  .header__right-menu {
    display: none;
  }

  .header__icon {
    margin-left: 10px;
    cursor: pointer;
  }
}

.SessionItem--hoverable:hover .header__right-menu {
  display: block;
}

.SessionItem--hoverable:hover .header__minutes {
  display: none;
}

.SessionItem--draggable {
  cursor: -webkit-grab;
}

.SessionItem--dragged {
  display: none;
}

.SessionItem__background {
  @include SessionItem__box;
  background-color: $black;
  box-shadow: $default-box-shadow;
  opacity: 0.1;
}

.SessionItem__drop-target {
  @include SessionItem__box;
  border: 1px dashed $black;
  opacity: 0.1;
}

.SessionItem__description {
  display: -webkit-box;
  width: 100%;
  height: 28px;
  /* autoprefixer: off */
  margin-top: 5px;
  padding-left: 25px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.SessionItem__description--finished {
  text-decoration: line-through;
}
