@import '../../variables';

.Counter {
  position: relative;

  svg.svgCounter {
    transform: rotate(225deg) scale(0.75, 0.75);
  }

  svg circle.svgCounter__background {
    transform: rotate(-90deg);
  }

  .Counter__time {
    position: absolute;
    top: 39%;
    left: 50%;
    color: $white;
    font-weight: bold;
    font-size: 56px;
    transform: translateX(-50%);
  }

  path {
    transition: 0.1s all ease;
  }
}
