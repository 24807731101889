@import '../../variables';

.Distractions {
  margin: 30px 10px;
  text-align: center;

  .Distractions__input {
    width: 332px;
    margin-top: 20px;
    padding: 5px;
    border: none;
    border-bottom: 2px solid $gainsboro;
    color: $tundora;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    outline: none;
    appearance: none;
  }
}
