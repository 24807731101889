@import '../../variables';

.SendEmail {
  position: relative;
  width: 350px;
  margin: 0 auto;

  // Action button
  .SendEmail__action-btn {
    position: relative;
    z-index: 2;
    box-sizing: border-box;

    width: 70%;
    height: 35px;
    margin: 0 auto;
    border-radius: 22px;
    cursor: pointer;
    transition: 0.2s;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      content: '';
    }

    &::before {
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background: linear-gradient(
        to right,
        $bg_gradient_dark,
        $bg_gradient_light
      );
    }

    &::after {
      background: $white;
    }
  }

  .SendEmail__action-btn.send-email {
    animation: enlarge-btn 0.2s forwards;
    &::before {
      background: linear-gradient(
        to right,
        $bg_gradient_dark,
        $bg_gradient_light
      );
      transition: background 1s;
    }
  }

  .SendEmail__action-btn.prepare-email {
    width: 17%;
    transform: translateX(150px);
    transition: 0.5s;
  }

  .SendEmail__action-btn.email-sent {
    cursor: default;
    animation: move-and-shorten-success 1.7s forwards;
    pointer-events: none;
    &::before {
      background: $apple;
      transition: 0.1s;
    }
  }

  .SendEmail__action-btn.email-sent.error {
    cursor: default;
    animation: move-and-shorten-error 1.7s forwards;
    pointer-events: none;
    &::before {
      background: $error-red;
      transition: background 0.1s;
    }
  }

  .action-btn__prepare-email-content {
    position: absolute;
    top: 7px;
    left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20px;
      margin-right: 15px;
    }

    span {
      color: #000;
      font-weight: 600;
      font-size: 14px;
    }
  }

  .action-btn__prepare-email-content.send-email {
    opacity: 1;
    transition: 0.5s 0.2s;
  }

  .action-btn__prepare-email-content.prepare-email {
    opacity: 0;
  }

  .action-btn__prepare-email-content.email-sent {
    opacity: 0;
    transition: all 0.1s 1s;
  }

  .action-btn__send-email-content {
    position: absolute;
    top: 9px;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    opacity: 0;
    transition: 0.1s;
  }

  .action-btn__send-email-content.send-email {
    opacity: 0;
  }

  .action-btn__send-email-content.prepare-email {
    left: 12px;
    opacity: 1;
    transition: 0.1s 0.2s;
  }

  .action-btn__send-email-content.email-sent {
    color: $apple;
    transform: translateX(40px);
    opacity: 0;
    transition: 0.6s;
  }

  // Email input
  .SendEmail__email-input {
    position: absolute;
    opacity: 0;
    transition: 0.1s;

    input {
      width: 100%;
      padding: 7px 7px 7px 40px;
      font-size: 16px;
      line-height: 16px;
    }

    &:before {
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 1;
      width: 35px;
      height: 25px;
      background: url('../../images/email-icon.png') no-repeat center;
      background-size: contain;
      content: '';
    }

    & .Input__wrapper {
      width: 250px;
    }
  }

  .SendEmail__email-input.send-email {
    opacity: 0;
    transition: 0.1s;
  }

  .SendEmail__email-input.prepare-email {
    z-index: 1;
    transform: translateX(30px);
    opacity: 1;
    transition: 0.2s;
  }

  .SendEmail__email-input.email-sent {
    z-index: -1;
    opacity: 0;
    transition: 0.1s;
  }

  // request status
  .SendEmail__request-status {
    position: absolute;
    top: 0;
    transform: translateX(200px);
    opacity: 0;
  }

  .SendEmail__request-status.send-email {
    z-index: -1;
    transform: translateX(100px);
    opacity: 0;
    transition: 0.5s;
  }

  .SendEmail__request-status.email-sent {
    z-index: 3;
    opacity: 1;
    animation: move-success 1.7s forwards;
  }

  .SendEmail__request-status.email-sent.error {
    animation: move-error 1.7s forwards;
  }

  .request-status__sent-icon {
    &:after {
      position: absolute;
      top: 8px;
      width: 7px;
      height: 14px;
      border: solid $apple;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      content: '';
    }
  }

  .request-status__sent-icon.error {
    &:before,
    &:after {
      position: absolute;
      top: 10px;
      left: 4px;
      width: 2px;
      height: 15px;
      border: none;
      background-color: $error-red;
      content: '';
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  .request-status__sent-text {
    position: absolute;
    top: 12px;
    left: 35px;
    color: $apple;
    font-weight: 600;
    font-size: 12px;
  }

  .SendEmail__request-status.error {
    .request-status__sent-text {
      min-width: 70px;
      color: $error-red;
    }
  }

  // send another
  .SendEmail__send-another {
    position: absolute;
    top: 9px;
    left: 175px;
    z-index: -1;
    opacity: 0;
    &:before {
      display: inline-block;
      width: 1px;
      height: 10px;
      margin-right: 15px;
      background-color: $silver;
      content: '';
    }

    span {
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .SendEmail__send-another.email-sent {
    z-index: 1;
    animation: fade-in 1.7s 0.2s forwards;
  }

  &--loading {
    opacity: 0.7;
    pointer-events: none;
  }

  &--loading:before {
    position: absolute;
    top: -2px;
    left: 25px;
    z-index: 10;
    width: 333px;
    height: 40px;
    border-radius: 20px;
    background: #f6f7f8;
    background: linear-gradient(
      to right,
      $mercury 8%,
      $gainsboro 18%,
      $mercury 33%
    );
    background-size: 350px 50px;
    opacity: 0.5;
    animation: loading 1.7s infinite forwards linear;
    content: '';
  }
}

.SendEmail--small {
  width: 250px;
  margin: 10px 0;

  .SendEmail__action-btn {
    width: 100%;
  }

  .SendEmail__action-btn.send-email {
    animation: enlarge-btn--small 0.2s forwards;
  }

  .SendEmail__action-btn.prepare-email {
    width: 23%;
    transform: translateX(100px);
  }

  .SendEmail__action-btn.email-sent {
    animation: move-and-shorten-success--small 1.7s forwards;
  }

  .SendEmail__action-btn.email-sent.error {
    animation: move-and-shorten-error--small 1.7s forwards;
  }

  .SendEmail__email-input.prepare-email {
    transform: translateX(0);

    input {
      padding-left: 7px;
    }

    &:before {
      display: none;
    }

    .Input__wrapper {
      width: 180px;
    }
  }

  .SendEmail__request-status.email-sent {
    animation: move-success--small 1.7s forwards;
  }

  .SendEmail__request-status.email-sent.error {
    animation: move-error--small 1.7s forwards;
  }

  .SendEmail__send-another {
    left: 90px;
  }

  .SendEmail__send-another.error {
    left: 135px;
  }
}

.SendEmail--small.SendEmail--loading {
  &:before {
    left: -5px;
    width: 260px;
  }
}

// mixins
@mixin move-and-shorten($final-width, $translate-x) {
  0% {
    width: 17%;
    transform: translateX(150px);
  }

  30% {
    width: $final-width;
    transform: translateX(-30px);
  }
  50% {
    width: $final-width;
    transform: translateX(-30px);
  }

  100% {
    width: $final-width;
    transform: translateX($translate-x);
  }
}

@mixin move($translate-1, $translate-2, $translate-3, $translate-4) {
  0% {
    transform: translateX($translate-1);
  }

  30% {
    transform: translateX($translate-2);
  }

  50% {
    transform: translateX($translate-3);
  }

  100% {
    transform: translateX($translate-4);
  }
}

// animations
@keyframes enlarge-btn {
  from {
    width: 17%;
  }
  to {
    width: 70%;
  }
}

@keyframes enlarge-btn--small {
  from {
    width: 17%;
  }
  to {
    width: 100%;
  }
}

@keyframes move-and-shorten-success {
  @include move-and-shorten(10%, -70px);
}

@keyframes move-and-shorten-success--small {
  @include move-and-shorten(14%, -105px);
}

@keyframes move-and-shorten-error {
  @include move-and-shorten(10%, -115px);
}

@keyframes move-and-shorten-error--small {
  @include move-and-shorten(14%, -105px);
}

@keyframes move-success {
  @include move(170px, 140px, 140px, 100px);
}

@keyframes move-success--small {
  @include move(170px, 90px, 90px, 15px);
}

@keyframes move-error {
  @include move(170px, 140px, 140px, 55px);
}

@keyframes move-error--small {
  @include move(170px, 90px, 90px, 15px);
}

@keyframes fade-in {
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    background-position: -350px 0;
  }
  100% {
    background-position: 350px 0;
  }
}
