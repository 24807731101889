@import "../../../../variables";

.AddSessionView {
  margin-top: 50px;
  padding-right: 67px;
  padding-left: 67px;

  input,
  label {
    width: 100%;
    font-size: 20px;
    line-height: 1.2;
  }

  label {
    font-weight: bold;
  }

  input {
    margin-top: 0.5em;
    margin-bottom: 2.3em;
    border: 0;
    border-radius: $border-radius-medium;
    color: $grey-dark;
    background-color: $bright-bg-input;
    outline: none;

    &::placeholder {
      color: rgba($grey-dark, 0.4);
      font-style: italic;
    }
  }
}

.AddView-title .edit-black {
  margin-right: 20px;
}

.AddSessionView-title {
  margin-bottom: 1.2em;
  font-size: 50px;
  line-height: 1.2;
}

.AddSessionView__tip {
  margin: -23px -40px 50px;
  padding: 30px 50px 40px;
  border-radius: $border-radius-medium;
  text-align: center;
  background: rgba($bright-bg-input, 0.6);
}

.AddSessionView__tip-heading {
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 50px;
  line-height: 66px;
}

.AddView__footer {
  margin-top: 60px;
  padding-top: 30px;
  border-top: 1px solid rgba($black, 0.1);
  font-weight: 700;
  font-size: 15px;
  text-align: center;

  .GoSereneButton {
    .btn__gradient-text {
      display: flex;
      justify-content: center;
      height: 52px;
      padding-top: 10px;
    }
  }

}
