@import '../../variables';

.BlockSites {
  position: relative;
  z-index: 1;
  width: 460px;
  height: 320px;
  border-radius: $border-radius-medium;
  text-align: left;
  background-color: $white;

  .BlockSites__list {
    height: calc(4 * 64px);
    overflow: auto;

    .List {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;

      .ListItem {
        > span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .BlockSites__input-wrapper {
    position: absolute;
    bottom: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 65px;
    padding: 10px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: $white;
    box-shadow: 0 0 40px -15px rgba($black, 0.75);
  }

  .BlockSites__input-wrapper--loading {
    cursor: initial;
    opacity: 0.4;
    pointer-events: none;

    input {
      color: transparent;
      text-shadow: 0 0 0 $black;
    }
  }

  .input-wrapper--upgrade {
    width: 100%;
    margin-top: 15px;
    color: $burnt-sienna;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    & span {
      font-weight: 600;
    }
  }

  .input-wrapper--limited {
    width: 100%;
    margin-top: 10px;
    color: $grey-dark;
    text-align: center;
    cursor: default;
    pointer-events: none;
  }

  .ListComponent__background {
    display: none;
  }

  .Input {
    width: 100%;
    height: 100%;
    padding: 6px 10px 7px;
    font-size: 16px;
    line-height: 16px;
  }

  .Input__wrapper {
    width: 100%;
  }

  .Button {
    width: 19%;
    height: 100%;
  }
}
