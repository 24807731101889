@import '../../variables';

.DayDebrief {
  height: 100%;
  padding: 25px 0;
  text-align: center;

  .DayDebrief__session-stack-container {
    display: inline-block;
    width: 90%;
    height: 200px;
    border-radius: $border-radius-medium;
    text-align: initial;
    background-color: $alabaster-2;
    box-shadow: 0 5px 0 5px $alabaster-1;
  }

  .DayDebrief__session-stack {
    position: relative;
    top: 50%;
    left: 50%;
    width: 80%;

    transform: translate(-50%, -25%);
  }

  .DayDebrief__session-goal-box {
    margin-top: 70px;
    margin-bottom: 50px;
    color: $grey-light;
    font-weight: 700;
    text-align: center;
  }

  .DayDebrief__day {
    font-size: 11px;
    font-style: italic;
    text-decoration: underline;
  }

  .DayDebrief__day--goal-text {
    font-style: normal;
  }

  .DayDebrief__goal {
    margin: 30px 10px 0;
    font-weight: 700;
    font-size: 22px;
  }
}
