@import '../../../../variables';

.AccountSettings {
  .SettingsContent__wrapper {
    margin-top: 48px;
  }

  .AccountSettings__row {
    display: grid;
    grid-row-gap: 10px;
    grid-template-columns: max-content auto;
  }

  .AccountSettings__row:nth-child(n + 1) {
    margin-top: 25px;
  }

  .Settings__tagline {
    max-width: 440px;
  }

  .AccountSettings__email-input {
    width: 300px;
    padding: 5px 10px;
    color: $grey-light;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    pointer-events: none;
  }

  .AccountSettings__row-checkbox {
    .AccountSettings__auto-start-cta,
    .checkbox__label {
      &:hover {
        cursor: pointer;
      }
    }

    .checkbox__label {
      &:before {
        right: 0;
        left: auto;
        margin-right: 0;
        background-color: $bright-bg-input;
        outline: none;
        transform: translateX(19px);
      }
    }
    .checkbox__input:checked + .checkbox__label {
      &:before {
        background-color: $grey-light;
      }
      &:after {
        right: -15px;
        left: auto;
        border-bottom: 2px solid $white;
        border-left: 2px solid $white;
      }
    }
  }

  .AccountSettings__auto-start-cta {
    margin: 0 0 0 35px;
  }

  .AccountSettings__confirmation,
  .AccountSettings__plan-name,
  .AccountSettings__auto-start-cta {
    font-weight: 700;
  }

  .AccountSettings__confirmation.link {
    text-decoration: underline;
    cursor: pointer;
  }

  .action-content__cancel {
    color: $coral-serene;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  .action-content__upgrade-card .Card {
    margin-top: -35px;
    margin-left: -20px;
    transform: scale(0.85);
  }

  label {
    align-self: center;
    min-width: 200px;
    padding-right: 1em;
    color: $grey-dark;
    text-align: right;
  }
}
