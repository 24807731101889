@import "../../../variables";

.TimeDropdown {
  position: relative;
  display: inline-block;
  padding-left: 0.25em;

  .TimeDropdown__down-arrow {
    position: absolute;
    top: 7px;
    right: -12px;
    width: 8px;
    height: 8px;
  }

  .TimeDropdown__clock {
    position: absolute;
    top: 0;
    left: -20px;
  }

  .TimeDropdown__text {
    position: relative;
    display: inline-block;
    margin-left: 1em;
    padding-right: 0;
    border-bottom: 1px solid $grey-light;
    color: $grey-light;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.1875;
    cursor: pointer;
  }

  .TimeDropdown__modal {
    position: absolute;
    top: 65px;
    left: 10px;
    z-index: 1;
    display: none;
    width: 86px;
    border-radius: $border-radius-medium;
    background-color: $white;
    box-shadow: $default-box-shadow;

    .TimeDropdown__item {
      padding-top: 0.25em;
      padding-right: 0.5em;
      padding-bottom: 0.25em;
      padding-left: 0.5em;
      color: $grey-light;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.1875;
      text-align: center;
      cursor: pointer;

      &:first-child {
        padding-top: 1em;
      }

      &:last-child {
        padding-bottom: 1em;
      }

      &:hover,
      &--selected {
        font-weight: bold;
      }
    }

    &--open {
      display: block;
    }
  }
}
