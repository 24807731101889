.Toastify {
  background: none;

  .Toastify__toast--default {
    overflow: visible;
    background: none;
    box-shadow: none;
  }

  .Toastify__close-button {
    display: none;
  }

  .NotificationCard {
    top: 0;
    right: 0;
  }
}
