@import "../../variables";

.AlertModal {
  position: relative;
  width: 400px;
  padding: 40px;

  .AlertModal__body {
    margin-top: 10px;
    font-size: 16px;
  }

  .AlertModal__buttons {
    margin-top: 20px;

    .Button {
      width: 135px;
      height: 44px;
    }
  }
}
