@import '../../variables';

.Card {
  width: 230px;
  border-radius: 7px;
  text-align: center;
  background: $white;
  box-shadow: $default-box-shadow;

  &--highlighted {
    margin-left: 15px;
    transform: scale(1.1);
  }
}

.Card__header {
  position: relative;
  height: 144px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: linear-gradient($card-gradient-from, $card-gradient-to);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 80% 80%;
  }

  &-basic::before {
    background-image: url('../../images/lite-plan.png');
  }

  &-pro::before {
    background-image: url('../../images/pro-plan.png');
  }

  &-team::before {
    background-image: url('../../images/team-plan.png');
  }
}

.Card__content {
  padding: 10px;
}

.content__title {
  position: relative;
}

.content__title:after {
  position: absolute;
  right: 0;
  bottom: -10px;
  left: 0;
  width: 30px;
  height: 1px;
  margin: auto;
  background-color: $black;
  content: '';
}

.content__price {
  height: 70px;
  margin-top: 20px;
}

.price__currency {
  color: $coral-serene;
  font-weight: 700;
  font-size: 18px;
}

.price__value {
  color: $coral-serene;
  font-weight: 700;
  font-size: 32px;
}

.price__period,
.price__info {
  color: $grey-light;
  font-size: 14px;
}
.price__info {
  color: $grey-light;
  font-size: 12px;
}

.benefits__list {
  margin-bottom: 20px;
  margin-left: 30px;
  text-align: left;
}

.list__item {
  position: relative;
  font-size: 13px;
  line-height: 20px;

  span {
    color: $coral-serene;
    font-size: 11px;
  }
}

.list__item {
  &:before {
    position: absolute;
    top: 5px;
    left: -15px;
    width: 4px;
    height: 9px;
    border: solid $apple;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    content: '';
  }
}

.content__cta {
  margin-bottom: 20px;
  .Button {
    width: 90%;
    font-size: 16px;
  }
}

.cta__current {
  margin-top: 40px;
  font-weight: 700;
  font-size: 16px;
  background: linear-gradient(90deg, $bg_gradient_dark, $bg_gradient_light);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
