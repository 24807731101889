@import '../../../../variables';

.BreakView {
  margin-top: 50px;
  padding-right: 97px;
  padding-left: 97px;
  text-align: center;

  &__add-new-session-btn {
    display: flex;
    justify-content: center;
    height: 44px;
    margin-top: 25px;
    padding: 0;

    .plus-sign {
      margin-right: 10px;
    }
  }

  .BreakView__title {
    margin-top: 90px;
  }

  .BreakView__session {
    margin-top: 124px;
    margin-bottom: 90px;
  }

  .BreakView__session-number {
    color: rgba($grey-dark, 0.5);
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
  }

  .BreakView__session-title {
    display: -webkit-box;
    padding-top: 5px;
    overflow: hidden;
    color: $grey-dark;
    font-weight: bold;
    font-size: 30px;
    -webkit-line-clamp: 3;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .BreakView__session-starting {
    display: -webkit-box;
    overflow: hidden;
    font-size: 20px;
    font-style: italic;
    line-height: 1.2;
    -webkit-line-clamp: 3;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .btn--double-gradient {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 26px;
    background: linear-gradient(to left, $coral-serene, $yellow-serene);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .GoSereneButton {
    width: 500px;
    margin-right: auto;
    margin-left: auto;

    .Button {
      width: 100%;
    }
  }
}

.BreakView__navigation {
  display: grid;
  grid-template-rows: max-content auto max-content;
  height: 100vh;
}
