// Add color here if you want use it in CONST LIST below
$bg_gradient_dark: #eb7070;
$bg_gradient_light: #ffd782;
$dusty-grey: #979797;
$puerto-rico: #4dccae;
$shamrock: #2ddd9d;
$silver: #c8c8c8;
$yellow-serene: #ffd166;

// CONST LIST - Keep alphabetical order
$alabaster: #f7f7f7;
$alabaster-1: #f8f8f8;
$alabaster-2: #f9f9f9;
$apricot: #f09b83;
$apple: #2ac941;
$bg-gradient: linear-gradient(180deg, $bg_gradient_dark, $bg_gradient_light);
$bg_gradient_dark_translucent: rgba($bg_gradient_dark, 0.4);
$bg_gradient_light_translucent: rgba($bg_gradient_light, 0.4);
$black: #000;
$body-color: #2d3846;
$bright-bg-input: #f4f4f4;
$burnt-sienna: #ed6f6d;
$carmine: #9e0013;
$concrete: #f2f2f2;
$copperfield:#df906d;
$coral-serene: #ff6763;
$cornflower-blue: #accef7;
$default-box-shadow: inset 0 -1px 1px 0 rgba($black, 0.12),
  0 0 10px 0 rgba($black, 0.14);
$ease-out-cubic-transition: cubic-bezier(0.215, 0.61, 0.355, 1);
$error-red: #d0021b;
$gainsboro: #d8d8d8;
$goal-meter-gradient: linear-gradient(
  270deg,
  $coral-serene 0%,
  $yellow-serene 100%
);
$grey-dark: #2d3846;
$grey-light: #9b9b9b;
$grey-text-input: #c0c3c6;
$medium-aquamarine: #4dccae;
$mercury: #e4e4e4;
$monza: #d50c23;
$peach: #ffe5b0;
$pomegranate: #f72828;
$porcelain: #f6f7f8;
$select-bg: #f4f4f4;
$shamrock: #2ddd9d;
$tundora: #484848;
$wewak: #f3908c;
$white: #fff;

$card-gradient-from: #ffc48a;
$card-gradient-to: #ff8e76;

// Border Radii
$border-radius-medium: 8px;
