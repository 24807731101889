@import '../../variables';

.DistractionItem {
  position: relative;
  display: inline-block;
  max-width: 270px;
  height: 22px;
  margin: 4px 5px;
  border-radius: 11.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: $default-box-shadow;

  .DistractionItem__text {
    display: inline-block;
    padding: 3px 10px;
    color: $grey-light;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
  }

  .DistractionItem__remove {
    display: none;
    margin: 0 10px 0 6px;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
  }

  &:hover {
    padding-right: 12px;
  }

  &:hover .DistractionItem__remove {
    position: absolute;
    top: 3px;
    right: 0;
    display: inline-block;
  }
}
