.CompletedSessionStack {
  position: relative;
  height: 170px;
  overflow: hidden;
}

.CompletedSessionStack__list {
  list-style-type: none;
}

.CompletedSessionStack__list .SessionItem {
  position: relative;
  width: 100%;

  /* stylelint-disable-next-line */
  @for $i from 0 to 4 {
    &:nth-child(#{$i + 1}) {
      z-index: calc(10 - #{$i});
      transform: translateY(calc(-#{$i} * 93px)) scaleX(calc(1 - calc(0.05 * #{$i})));
    }
  }
}
