@import '../../../../variables';

.DaySummary {
  padding-top: 40px;
  text-align: center;

  .DaySummary__session-stack-container {
    display: inline-block;
    width: 90%;
    padding-top: 50px;
    border-radius: $border-radius-medium;
    text-align: initial;
    background-color: $alabaster-2;
    box-shadow: 0 5px 0 5px $alabaster-1;
  }

  .CompletedSessionStack {
    height: 150px;
  }

  .DaySummary__day-state {
    margin-top: 50px;
  }

  .DaySummary__productivity-factor {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .SessionItem {
    width: 368px;
    margin-right: auto;
    margin-left: auto;
  }

  .DaySummary__title {
    font-size: 35px;
  }

  .DaySummary__title--goal {
    margin-top: 70px;
  }


  .StateRadioGroup {
    margin-top: 32px;
    margin-bottom: 23px;
  }

  .ProductivityRadioGroup {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .StateRadio__box {
    width: 105px;
    height: 105px;
    margin: 19px;
  }
}
