@import '../../../variables';

.GreatWork {
  .invitation__question {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
  }

  .invitation__info {
    margin-bottom: 30px;
    font-size: 14px;
  }

  .info__number {
    color: $coral-serene;
    font-weight: 700;
  }

  .invitation__or {
    margin: 15px 0 2px;
    color: $grey-light;
    font-size: 12px;
  }

  .invitation__copy-link-btn {
    width: 170px;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;

    &--clicked {
      cursor: default;
      pointer-events: none;
    }
  }
}
