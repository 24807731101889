@import '../../../../../variables';

.SettingsAccountConfirmation__confirm {
  padding: 10px 40px;
}

.SettingsAccountConfirmation {
  color: #2d3846;
  text-align: center;
  .error {
    padding-top: 10px;
    color: #d0021b;
    font-weight: bold;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    width: 30px;
    height: 30px;
    padding: 5px;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.05);
    }
  }
  h2 {
    margin-bottom: 20px;
    font-size: 38px;
  }
  p {
    margin: 10px 20px;
    color: #2d3846;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  a {
    color: $coral-serene;
    font-weight: bold;
    cursor: pointer;
  }
  a:hover {
    text-decoration: underline;
  }
  footer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    p {
      margin: 0px;
    }
    small {
      padding: 5px;
      color: #9b9b9b;
      font-size: 14px;
    }
  }
  .input__holder {
    margin: 20px 0px 40px 0px;
    .Input {
      text-align: center;
    }
    &.error {
      .Input {
        border-color: #d0021b;
      }
    }
  }
  .SettingsAccountConfirmation__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background: rgba($white, 0.9);
    align-items: center;
  }
  .Loader__container {
    .Loader {
      width: 60px;
      height: 60px;
      padding: 10px 11px;
      border-radius: 50%;
      background: linear-gradient(#eb7070, #ffd782);
    }
  }

  .overlay__title {
    margin-bottom: 30px;
    color: $grey-dark;
    font-weight: bold;
    font-size: 32px;
    text-align: center;
  }
}
