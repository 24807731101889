@import '../../variables';

.TipBox {
  position: absolute;
  right: 0;
  bottom: 15px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  margin: 0 auto;
  padding: 20px 15px;
  border-radius: 5px;
  color: $black;
  background-color: $peach;

  .TipBox__icon {
    svg g {
      fill: $black;
    }
  }

  .TipBox__text {
    flex-grow: 1;
    margin-left: 15px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
  }

  .TipBox__gauge {
    display: flex;
    flex-grow: 4;
    flex-direction: column;
    justify-content: center;
  }

  .gauge__title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 10px;
    text-transform: uppercase;
  }

  .gauge__progress-box {
    display: flex;
    align-items: center;
  }

  .progress-box__progess-bar {
    width: 100%;
    height: 15px;
    margin: 0 5px;
    border-radius: 10px;
    background-color: rgba($black, 0.3);
    box-shadow: 0 0 7px 1px rgba($black, 0.1);
  }

  .progess-bar__clip {
    height: 100%;
    clip-path: inset(0 0 0 0 round 5px);
  }

  .clip__fill {
    max-width: 100%;
    height: 100%;
    border-radius: 10px;
    background: $goal-meter-gradient;
    transition: 2s $ease-out-cubic-transition;
  }

  .progress-box__scale {
    margin: 0 5px;
    font-size: 10px;
    text-transform: uppercase;
  }
}
