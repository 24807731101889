@import '../../variables';

.ProductivityRadioGroup {
  display: grid;
  grid-template-columns: repeat(4, 120px);
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  column-gap: 20px;
  place-content: center;

  .ProductivityRadio__box {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 115px;
    padding: 15px;
    border-radius: $border-radius-medium;
    text-align: center;
    background-color: $white;
    box-shadow: $default-box-shadow;
    cursor: pointer;
    transition: border 50ms ease-in-out;
    user-select: none;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
    }

    &::after {
      top: -3px;
      left: -3px;
      z-index: -2;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      border-radius: $border-radius-medium;
    }

    &::before {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background: $white;
    }
  }

  span {
    font-size: 40px;
  }

  input[type="radio"]:checked {
    + .ProductivityRadio__box::after {
      background: $black;
    }
  }

  .ProductivityRadio__text {
    color: $grey-light;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }

  input {
    display: none;
  }
}
