@import '../../../variables';

.Settings {
  .Settings__nav {
    color: $white;
  }

  .Settings__nav-list {
    margin: 15px 0 0 30px;
  }

  .Settings__nav-item {
    position: relative;
    display: block;
    padding: 17px 0;
    color: $white;
    font-size: 22px;
    line-height: 26px;
    text-decoration: none;

    &.Settings__nav-item--active {
      font-weight: 700;
      pointer-events: none;
    }

    &:not(:last-child)::after {
      position: absolute;
      bottom: 0;
      left: -20px;
      width: calc(100% + 45px);
      height: 1px;
      background-color: $white;
      content: '';
    }
  }

  .Settings__header {
    margin: 0;
    font-size: 32px;
    line-height: 38px;
  }

  .Settings__tagline {
    margin-top: 10px;
    color: $grey-light;
    font-size: 16px;
    line-height: 19px;

    p:nth-child(n + 1) {
      margin-top: 10px;
    }

    a {
      color: inherit;
    }

    &.Settings__tagline--bold {
      font-weight: 700;
    }
  }
}

.SettingsContent {
  padding: 55px 20px 0 50px;

  .SettingsContent__wrapper {
    margin-top: 30px;
  }
}
