@import '../../variables';

.Navigation {
  display: flex;
  height: 100vh;

  .Navigation__bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 7%;
  }

  .bar__middle {
    height: 100%;
  }

  .Navigation__view {
    width: 100%;
    background: $bg_gradient;

    &--has-nav {
      width: 93%;
    }

    &--has-no-nav {
      .Menu {
        padding-top: 15px;
      }
    }
  }
}
