

@import "../../../variables";

.checkbox__label {
  position: relative;
  padding-left: 30px;

  // the check box
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 1px solid $white;
    border-radius: 4px;
    background-color: rgba(246, 246, 246, 0.33);
    content: "";
  }

  // the tick!
  &::after {
    position: absolute;
    top: 5px;
    left: 4px;
    display: none;
    width: 8px;
    height: 4px;
    border-bottom: 2px solid rgba($apricot, 0.9);
    border-left: 2px solid rgba($apricot, 0.9);
    border-radius: 2px;
    transform: rotate(-45deg);
    content: '';
  }
}

.checkbox__input {
  &:checked + .checkbox__label {
    &::before {
      background-color: $white;
    }

    &::after {
      display: block;
    }
  }

  &.invalid {
    + label {
      color: $error-red;
      font-weight: bold;
    }
  }

  &:focus + .checkbox__label::before {
    outline: -webkit-focus-ring-color auto 5px;
  }
}

// make the checkbox focusable but hidden
.checkbox__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
}

