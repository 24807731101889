@import '../../variables';

.OnboardingBlockSites {
  height: 100vh;
  color: $white;
  background: url('../../images/onboarding-bg.png') no-repeat bottom / contain;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .OnboardingBlockSites__title {
    font-size: 36px;
  }

  .OnboardingBlockSites__caption {
    margin: 20px 0 30px;
    font-size: 20px;

    a {
      color: inherit;
      font-weight: bold;
    }
  }

  .OnboardingBlockSites__btn {
    width: 43%;
    margin: 30px 0;
  }

  .OnboardingBlockSites__skip {
    cursor: pointer;
  }
}
