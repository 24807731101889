@import '../../../variables';

.Tooltip {
  position: absolute;
  right: 10px;
  bottom: -80px;
  max-width: 390px;
  padding: 15px;
  border-radius: $border-radius-medium;
  font-weight: 500;
  font-size: 12px;
  text-align: justify;
  background: $white;
  box-shadow: 0 0 10px 0 rgba($black, 0.44);
  opacity: 0;
  transition: opacity 0.1s ease-in;
  pointer-events: none;
}

.Tooltip--visible {
  opacity: 0.95;
  pointer-events: auto;
}

.Tooltip:after {
  position: absolute;
  right: 15px;
  bottom: 100%;
  border: 10px solid transparent;
  border-bottom-color: $white;
  content: ' ';
  filter: drop-shadow(0 0 $black);
}

.Tooltip--current-session.Tooltip {
  position: absolute;
  top: -50px;
  bottom: auto;
  max-width: 390px;
  padding: 10px;
  border-radius: $border-radius-medium;
  font-size: 10px;
  opacity: 0.85;

  &:after {
    top: 100%;
    border: 7px solid transparent;
    border-top-color: $white;
  }

  &--end-session-early {
    right: 240px;

    &:after {
      right: 45px;
    }
  }

  &--restart-session {
    right: 187px;

    &:after {
      right: 37px;
    }
  }

  &--pause-session {
    right: 126px;

    &:after {
      right: 37px;
    }
  }

  &--unpause-session {
    right: 119px;

    &:after {
      right: 44px;
    }
  }

  &--mute-music {
    right: 72px;

    &:after {
      right: 31px;
    }
  }

  &--unmute-music {
    right: 65px;

    &:after {
      right: 38px;
    }
  }
}
