.Shortcuts {
  display: grid;
  grid-template-columns: auto 50%;
  width: 100%;
  padding: 40px 10px;

  ul {
    margin-bottom: 0.5em;
    margin-left: 1em;
    list-style: disc;
  }

  > ul {
    padding-left: 0.5em;
  }

  li {
    margin-top: 0.5em;
  }

  a {
    text-decoration: none;
  }

  button {
    margin-top: 2em;
    padding: 1em 2em;
  }

  h3 {
    margin-top: 1em;
  }

  h2 {
    span {
      color: darkslategray;
      font-size: 50%;
    }
  }
}

.Shortcuts__contexts {
  > div {
    margin-bottom: 2em;
  }
}

.Shortcuts__tube {
  position: absolute;
  bottom: -4px;
  left: calc(50% - 78px);
  z-index: -1;
  height: 100px;
  text-align: center;

  img {
    width: auto;
    height: 200px;
  }
}
