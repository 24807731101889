.WeekPlanner {
  width: 100%;

  .WeekPlanner__title {
    margin: 10px 0 20px;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
  }

  .WeekPlanner__graph {
    margin-bottom: 10px;
  }
}
