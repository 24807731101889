@import '../../../../variables';

.SettingsBlocks {
  .SettingsBlocks__content {
    margin-left: 67px;
  }

  .SettingsBlocks__white-box {
    width: 460px;
    margin: 20px 0;
    border-radius: 4px;
    background-color: $white;
    box-shadow: $default-box-shadow;
  }

  .content__toggle-wrapper {
    margin-left: 15px;

    &--disabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &--sites {
    .SettingsBlocks__content {
      margin-bottom: 2rem;
    }

    .btn--gradient--chrome {
      margin-right: 15px;
    }
  }
}
