@import '../../variables';

.GraphPoint {
  position: relative;
  display: flex;
  flex: 0 0 56px;
  flex-direction: column;
  align-items: center;
  height: 80px;
  padding-top: 8px;
  transform: translateX(-700%);

  // Letters
  .GraphPoint__day {
    position: absolute;
    top: 45px;
    font-size: 14px;
  }

  .GraphPoint__status--active + .GraphPoint__day {
    font-size: 16px;
  }

  // Line
  .GraphPoint__status::before {
    position: absolute;
    top: 18px;
    display: block;
    width: 38px;
    border: 1px solid white;
    transform: translateX(18px);
    content: " ";
  }

  .GraphPoint__status--dashed::before {
    position: absolute;
    top: 9px;
    display: block;

    height: 2px;
    border: none;
    background-image: linear-gradient(to right, $white 50%, transparent 50%);
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 10px 2px;
    content: " ";
  }

  .GraphPoint__status--active::before {
    top: 13px;
    left: 18px;
    width: 28px;
  }

  .GraphPoint__status--dashed-fix::before {
    left: 5px;
    width: 30px;
  }

  .GraphPoint__status--remove-dash::before {
    display: none;
  }

  // Dots
  .GraphPoint__status {
    flex: 0 0 22px;
    width: 22px;
    margin-bottom: 10px;
    border: 2px solid $white;
    border-radius: 50%;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
    cursor: pointer;
  }


  .GraphPoint__status--planned, .GraphPoint__status--notplanned {
    position: relative;
    top: -1px;
    flex: 0 0 26px;
    width: 26px;
  }

  .GraphPoint__status--today {
    background: linear-gradient(180deg, $coral-serene, $yellow-serene);
  }

  .GraphPoint__status--active {
    position: relative;
    top: -5px;
    flex: 0 0 34px;
    width: 34px;
    margin-bottom: 0;
  }

  .GraphPoint__status--notfinished {
    background: $mercury;
    cursor: unset;
    pointer-events: none;
  }

  .GraphPoint__status--done {
    background: linear-gradient(180deg, $shamrock, $medium-aquamarine);
  }

  .GraphPoint__status--not_done {
    background: linear-gradient(180deg, $coral-serene, $wewak);
  }

  .GraphPoint__status--planned {
    background-color: rgba($white, 0.4);
  }

  .GraphPoint__status--not_planned {
    background-color: rgba($white, 0.3);
  }

  // Tick icon
  .GraphPoint__status--planned::after {
    position: relative;
    top: 7px;
    left: 7px;
    display: block;
    width: 7px;
    height: 4px;
    border-bottom: 2px solid $white;
    border-left: 2px solid $white;
    border-radius: 1px;
    transform: rotate(-45deg);
    content: '';
  }

  .GraphPoint__status--active::after {
    top: 10px;
    left: 10px;
    width: 8px;
    height: 5px;
    border-width: 3px;
    border-radius: 2px;
  }
}

//Transitions 
.GraphPoint--left {
  transform: translateX(0);
  transition: 0.4s transform ease-in-out;
}

.GraphPoint--right {
  transform: translateX(-1400%);
  transition: 0.4s transform ease-in-out;
}

