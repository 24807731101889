@import '../../../../variables';

.AssessingView {
  padding-top: 40px;
  text-align: center;

  .AssessingView__title {
    margin-bottom: 25px;
    font-size: 35px;
    line-height: 41 / 35 * 1;
  }

  .AssessingView__session-state {
    margin-top: 50px;
  }

  .AssessingView__distractions {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .StateRadioGroup {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .StateRadio__box {
    margin-top: 17px;
    margin-bottom: 17px;
    padding: 20px 0;
  }

  .AssessingView__session-stack-container {
    display: inline-block;
    width: 90%;
    padding-top: 40px;
    padding-bottom: 33px;
    border-radius: $border-radius-medium;
    text-align: initial;
    background-color: $alabaster-2;
    box-shadow: 0 5px 0 5px $alabaster-1;
  }

  .SessionItem__description--finished {
    text-decoration: none;
  }

  .SessionItem {
    width: 368px;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
  }
}

.AssessingView__navigation {
  display: grid;
  grid-template-rows: max-content auto max-content;
  height: 100vh;
}
