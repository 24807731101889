@import '../../variables';

.Graph {
  display: flex;
  width: 100%;
  padding: 0 10px;

  $arrow-height: 9px;

  .Graph__dates {
    position: absolute;
    color: rgba($white, 0.6);
    font-weight: 600;
    font-size: 13px;
    transform: translateY(-36px);
  }

  .Graph__arrow {
    width: 3%;
    height: $arrow-height;
    margin-top: 10px;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
  }

  .Graph__arrow--left {
    border-top: $arrow-height solid transparent;
    border-right: $arrow-height solid currentColor;
    border-bottom: $arrow-height solid transparent;
  }

  .Graph__arrow--right {
    border-top: $arrow-height solid transparent;
    border-bottom: $arrow-height solid transparent;
    border-left: $arrow-height solid currentColor;
  }

  .Graph__arrow--disabled {
    color: rgba($white, 0.6);
  }

  .Graph__points {
    display: flex;
    width: 92%;
    margin: 0 1%;
    overflow: hidden;
  }
}
