@import '../../../variables';

.NavItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65px;
  padding: 15px 0;
  color: white;
  text-align: center;
  text-decoration: none;

  .NavItem__caption {
    margin-top: 2px;
    font-weight: 500;
    font-size: 10px;
  }
}

.NavItem--logo {
  height: 80px;
  padding: 40px 0 5px;
}

.NavItem--logo svg {
  width: 24px;
}

.NavItem--active {
  border-radius: $border-radius-medium 0 0 $border-radius-medium;
  background-image: url('../../../images/buttons_bg.png');
  background-repeat: repeat-x;
  pointer-events: none;

  .bar__top & {
    &:nth-child(2) {
      background-position-y: -95px;
    }

    &:nth-child(3) {
      background-position-y: -165px;
    }

    &:nth-child(4) {
      background-position-y: -235px;
    }

    &:nth-child(5) {
      background-position-y: -305px;
    }
  }
  /* stylelint-disable */
  .bar__bottom & {
    &:nth-child(1) {
      background-position-y: -626px;
    }
  }
  /* stylelint-enable */
}

.NavItem--active::before {
  position: absolute;
  top: 0;
  left: -4px;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: inset 7px 0 0 0 transparent, -20px 0 0 0 transparent;
  content: '';
}

.NavItem--disabled {
  cursor: initial;
  opacity: 0.3;
  pointer-events: none;
}
