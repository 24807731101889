@import '../../../variables';

.ResizableTextArea {
  border: none;
  border-radius: $border-radius-medium;
  font-size: 26px;
  line-height: 31px;
  background-color: rgba($black, 0.1);
  outline: none;
  transition: all 0.3s ease-in;
  resize: none;

  &.ResizableTextArea--transparent {
    color: $white;

    &::placeholder {
      color: rgba($white, 0.6);
    }

    &:hover {
      box-shadow: 0 0 11px -2px $bg_gradient_dark;
    }

    &:focus {
      background-color: rgba($black, 0.18);
      box-shadow: 0 0 11px -2px $bg_gradient_dark;
    }

    &[disabled] {
      background-color: rgba($white, 0.3);
    }
  }
}
