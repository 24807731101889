@import '../../variables';

.BlockedSitesToggles--social {
  display: none;
  width: calc(100% + 40px);
  margin-top: 20px;
  margin-left: -40px;

  .toggle__checkbox {
    position: relative;
    bottom: 5px;
  }

  .toggle__label {
    margin-left: 15px;
  }
}

.BlockedSitesToggles__item-name {
  position: relative;
  bottom: 14px;
}

.BlockedSitesToggles__item {
  padding: 18px 0 19px 20px;
}

.BlockedSitesToggles__item .BlockedSitesToggles__item {
  padding-top: 10px;
  padding-bottom: 11px;
}

.BlockedSitesToggles__item .BlockedSitesToggles__item {
  padding-left: 84px;
}

.BlockedSitesToggles__social-toggle {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 15px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 8px;
    left: -8px;
    width: 0;
    height: 0;
    margin-left: 10px;
    border-width: 8px 8px 0 8px;
    border-style: solid;
    border-color: $tundora transparent transparent transparent;
    transition: transform 0.1s ease;
  }

  &--active {
    transform: rotateX(180deg);
  }
}

.BlockSites__input-wrapper + .BlockedSitesToggles,
[data-items-odd='false'] + .BlockedSitesToggles {
  > .BlockedSitesToggles__item {
    background-color: $bright-bg-input;

    &:nth-child(even) {
      background-color: $white;
    }
  }

  .BlockedSitesToggles {
    .BlockedSitesToggles__item:nth-child(odd) {
      background-color: $white;
    }
  }
}


[data-items-odd='true'] + .BlockedSitesToggles {
  > .BlockedSitesToggles__item {
    background-color: $bright-bg-input;

    &:nth-child(odd) {
      background-color: $white;
    }
  }

  .BlockedSitesToggles {
    .BlockedSitesToggles__item:nth-child(odd) {
      background-color: $bright-bg-input;
    }
  }
}

.BlockedSitesToggles {
  .toggle__label {
    font-weight: 500;
  }
}

.list-item__icon--favicon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: cover;
}

$social_list: 'facebook', 'twitter', 'youtube', 'pinterest', 'instagram', 'linkedin';

@each $social in $social_list {
  .list-item__icon--favicon-#{$social} {
    background-image: url('../../images/social/#{$social}.png');
  }
}

.BlockedSitesToggles__item {
  &--open {
    padding-bottom: 0;

    .BlockedSitesToggles__social-toggle {
      transform: rotateX(180deg);
    }

    .BlockedSitesToggles--social {
      display: block;
    }
  }
}
