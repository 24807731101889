@import '../../../variables';

.SessionList {
	position: relative;
	height: 100%;
	overflow: auto;

	&::after,
	&::before {
		position: sticky;
		left: 0;
		display: block;
		width: 100%;
		height: 4px;
		content: "";
	}

	&::before {
		top: 0;
	}

	&::after {
		bottom: 0;
		transform: rotate(180deg);
	}



	&::-webkit-scrollbar {
	  width: 8px;
	}

	&::-webkit-scrollbar-track {
	  background: none;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
		background: rgba(0,0,0, .25);
	}

	> div:last-of-type .SessionItem{
		margin-bottom: 0;
	}

	&--overflow {
		&.SessionList--top::before,
		&.SessionList--bottom::after {
			background:
				radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.3), rgba(0,0,0,0)),
				radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.3), rgba(0,0,0,0)) 0 100%;
		}
	}

}




