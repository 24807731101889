@import '../../../../variables';

.SessionsOverview {
  --menu: 410px;
  --content: auto;

  display: grid;
  grid-template-columns: var(--menu) var(--content);

  &--add {
    --menu: 288px;
  }

  h1 {
    display: block;
    margin: 10px 0 30px;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
  }

  h3 {
    display: block;
    margin: 18px 0;
    color: $white;
    font-weight: bold;
    font-size: 12px;
  }

  .SessionsOverview__menu {
    position: relative;
    display: grid;
    grid-template-rows: max-content auto max-content;
    height: 100vh;
    padding: 0px 25px 20px 20px;

    .SessionsOverview__menu-day-plan {
      color: $white;
    }

    .session-list__btn {
      width: 100%;
      margin-bottom: 30px;
    }

    .plus-sign {
      display: inline-block;
      margin-right: 5px;
      font-size: 28px;
      line-height: 0;
      transform: translateY(2px);
    }

    .GoSereneButton {
      width: 100%;
    }

    .GoSereneButton__bg {
      position: absolute;
      bottom: -50px;
      left: 0;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .GoSereneButton__content {
      width: calc(100% - 45px);
    }
  }

  .SessionsOverview__content {
    position: relative;
    flex-grow: 1;
    height: 100vh;
    border: 1px solid rgba($white, 0.22);
    background-color: $white;
    box-shadow: 0 0 25px 0 rgba($black, 0.22);

    .content {
      position: relative;
      top: 50%;
      text-align: center;
      transform: translateY(-60%);
    }

    .content__help-text {
      color: $grey-light;
      font-size: 18px;
      line-height: 21px;
    }

    .content__help-arrow {
      position: absolute;
      transform: scale(1, -1) translate(-15px, 40px);
    }

    .content__btn {
      display: inline-block;
      width: 70%;
    }
  }

  .SessionsOverview__menu-footer {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
  }

  .SessionsOverview__sessions-planned {
    position: relative;
    margin-bottom: 75px;
    color: $bg_gradient_dark;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;

    &::after {
      position: absolute;
      bottom: -17px;
      left: calc(50% - 57px);
      display: block;
      width: 114px;
      height: 1px;
      background-color: rgba($white, 0.8);
      content: '';
    }
  }
}

// .SessionsOverview--add {
//   .SessionsOverview__menu {
//     .session-list {
//       height: 500px;
//     }
//   }
// }
