@import "variables";

body {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  color: $body-color;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  background: linear-gradient($bg_gradient_dark, $bg_gradient_light);
  -webkit-user-select: none;
  -webkit-font-smoothing: antialiased;

  &[data-translucent=true] {
    background: linear-gradient($bg_gradient_dark_translucent, $bg_gradient_light_translucent);
  }
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

ul {
  padding-left: 0;
  list-style: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
}

html,
body {
  height: 100%;
}

div#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.app-container {
  position: relative;
  flex-grow: 1;
}

button.btn--no-style {
  padding: 0;
  border: none;
  color: inherit;
  font: inherit;
  background: none;
  outline: inherit;
  cursor: pointer;
}
