@import '../../../../variables';

.HotkeysSettings {
  .SettingsContent__wrapper {
    margin-top: 48px;
  }

  .HotkeysSettings__row {
    display: grid;
    grid-row-gap: 10px;
    grid-template-columns: max-content auto;
  }

  .Settings__tagline {
    max-width: 440px;
  }

  .row__hotkey-error {
    color: $error-red;
  }

  label {
    align-self: center;
    padding-right: 1em;
    color: $grey-dark;
    text-align: right;
  }
}
