@import "../../variables";

.onboarding__button {
  width: 100%;
  margin-top: 0.3em;
  text-align: center;
}

.onboarding {
  max-width: 535px;
  margin: 80px auto;

  .Input[type="text"] {
    width: 300px;
    margin-top: 0.5em;
    font-weight: 400;
    font-size: 26px;

    &.invalid {
      border-color: $error-red;
      color: $error-red;
    }
  }

  a {
    color: inherit;
  }

  label {
    cursor: pointer;
  }
}

.onboarding__logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.onboarding__title,
.onboarding__sub-title {
  margin-top: 0.25em;
  margin-bottom: 0;
  color: $white;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
}

.onboarding__sub-title {
  margin-top: 0.25em;
  font-weight: normal;
  font-size: 20px;
}

.onboarding__choices {
  position: relative;
  margin: 0.8em 0;
  padding: 0.8em 0.9em 0.6em;
  border-radius: $border-radius-medium;
  font-weight: 300;
  font-size: 26px;
  text-align: center;
  background-color: $white;
  box-shadow: $default-box-shadow;

  .Tooltip {
    bottom: -100px;
    z-index: 2;
  }
}

.onboarding__choices:after {
  display: table;
  clear: both;
  content: "";
}

.onboarding__question {
  display: inline-block;
  float: right;
  margin-top: 15px;
  border-bottom: 1px dashed $dusty-grey;
  color: $dusty-grey;
  font-weight: 400;
  font-size: 10px;
  text-align: right;
}

.onboarding__row {
  text-align: center;
}

.onboarding__row-checkbox {
  padding-bottom: 2em;
  font-size: 14px;
  line-height: 21px;

  & + & {
    position: relative;
    padding-top: 2em;

    &::after {
      position: absolute;
      top: 0;
      left: calc(50% - 180px);
      display: block;
      width: 360px;
      height: 1px;
      background-color: rgba($white, 0.31);
      content: "";
    }
  }
}

.onboarding__row-link {
  text-decoration: underline;
  cursor: pointer;
}

.onboarding__choices {
  .InputSelect {
    .InputSelect__select {
      font-weight: 400;
    }
  }
}

.onboarding__select-wrapper {
  position: relative;
  display: inline-block;

  .Input__wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }

  .Input[type="text"] {
    z-index: 1;
    width: 100%;
    margin-top: 0;
    padding-right: 1.5em;
    font-weight: 400;
  }
}

.onboarding__close-other {
  position: absolute;
  top: -5px;
  right: 4px;
  z-index: 1;
  padding: 14px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
}


