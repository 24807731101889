@import '../../../variables';

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba($white, 0.95);
  visibility: hidden;

  .Modal__close-btn {
    position: absolute;
    top: 10%;
    right: 10%;
    z-index: 1;
    display: inline-block;
    cursor: pointer;
  }

  .Modal__content {
    position: relative;
    top: 50%;
    width: 50%;
    min-width: 400px;
    margin: auto;
    transform: translateY(-50%);
  }

  .Modal__title {
    margin: 10px 0;
    color: $grey-dark;
    font-weight: bold;
    font-size: 32px;
    text-align: center;
  }

  .Modal__caption {
    color: $grey-light;
    font-size: 16px;
    text-align: center;
  }

  .Modal__white-box-container {
    position: relative;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }

  .Modal__white-box {
    display: inline-block;
    margin-top: 20px;
    border-radius: 4px;
    overflow: auto;
    background-color: $white;
    box-shadow: $default-box-shadow;
  }

  .Button {
    display: none;
  }
}

.Modal--opened {
  visibility: visible;

  .Button {
    display: inline-block;
  }
}

.Modal--wide {
  .Modal__content {
    width: auto;
  }
}
